import React, { FunctionComponent } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";
import Section from "../Section";
import HomeBanner, { HomeBannerInterface } from "../HomeBanner";
import "./styles.scss";

interface HomeBannerSliderInterface {
  name?: string;
  slides: HomeBannerInterface[];
  bgColor: {
    value: string;
    title: string;
  };
}

const HomeBannerSlider: FunctionComponent<HomeBannerSliderInterface> = ({ name, slides, bgColor }) => {
  return (
    <Section sectionClassName="home-banner-slider" theme={bgColor?.title}>
      <Swiper
        direction={"horizontal"}
        pagination={{
          clickable: true
        }}
        effect={"fade"}
        modules={[Pagination, Mousewheel]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <HomeBanner {...slide} firstSlide={index == 0} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
};

export default HomeBannerSlider;

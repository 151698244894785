import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { ImageInterface, SanityCtaBlock, VideoInterface } from "../../types/SanityTypes";
import VideoBlock from "../VideoBlock";
import CtaBlock from "../CtaBlock";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

export interface HomeBannerInterface {
  firstSlide?: boolean;
  heading: string;
  bannerLabel?: string;
  contentAlignment?: string;
  isVideoBanner?: boolean;
  homeBannerImage?: ImageInterface;
  _rawHomeBannerImage?: ImageInterface;
  _rawHomeBannerVideo?: VideoInterface;
  ctaLink?: SanityCtaBlock;
}

const HomeBanner: FunctionComponent<HomeBannerInterface> = slide => {
  const sanityImage = slide.homeBannerImage;
  const video = slide._rawHomeBannerVideo;
  const ctaBlock = slide.ctaLink;

  return (
    <div className={`home-banner alignment--${slide.contentAlignment}`}>
      <div className="home-banner__bg">
        {video && slide.isVideoBanner ? (
          <VideoBlock
            classnames="home-banner__bg-video"
            webm={video.webm}
            fallback={video.fallback}
            alt={video.alt}
            autoPlay
            muted
            loop
            hasPauseButton={true}
          />
        ) : (
          !slide.isVideoBanner &&
          sanityImage && (
            <SanityImage
              sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
              {...sanityImage}
              className="home-banner__bg-img"
              width={644}
              height={453}
              htmlWidth={644}
              htmlHeight={453}
            />
          )
        )}
      </div>
      <div className={`home-banner__content`}>
        {slide.bannerLabel && <div className="home-banner__hero-label smb-xs-24 smb-xs-36">{slide.bannerLabel}</div>}
        {slide.heading &&
          (slide.firstSlide ? (
            <p className="home-banner__heading smb-xs-24 smb-xs-36">{slide.heading}</p>
          ) : (
            <p className="home-banner__heading smb-xs-24 smb-xs-36">{slide.heading}</p>
          ))}
        {ctaBlock && <CtaBlock ctaBlock={ctaBlock} ctaLabel={ctaBlock?.ctaLabel} linkClassName="home-banner__cta" />}
      </div>
    </div>
  );
};

export default HomeBanner;
